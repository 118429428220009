<template>
  <div>
    <Head-section></Head-section>
    <div class="container">
      <ol class="breadcrumb bg_img_order justify-content-center align-items-center p-3">
        <li class=" text-white font-weight-bold">{{$t("message.Shopping_Bag")}}</li>
        <li class="text-muted font-weight-bold">{{$t("message.Checkout_Details")}}</li>
        <li class="text-muted  font-weight-bold">{{$t("message.Complete_Order")}}</li>
      </ol>

      <div class="table-responsive">
        <table class="table">
          <tbody>
            <tr>
              <th scope="col"></th>
              <th scope="col">{{$t("message.Shipping_Itmes")}}</th>
              <th scope="col" v-if="is_customize">{{$t("message.Customize_message")}}</th>
              <th scope="col">{{$t("message.QUANTITY")}}</th>
              <th scope="col">{{$t("message.Subtotal")}}</th>
            </tr>
            <tr v-for="(itemCart,idx) in items_cart" :key="itemCart.id">
              <th scope="row" @click="delShopCartId(itemCart.id)">
                <i class="far fa-trash-alt"></i>
              </th>

              <td>
                <figure class="figure" @click="toProDet(itemCart.sku.productid)">
                  <img
                    v-bind="mainProps"
                    :src="itemCart.sku.images[0].path"
                    class="figure-img img-fluid rounded whr_as_10"
                    alt="..."
                    v-if="itemCart.sku.images.length>0"
                  >
                  <figcaption class="figure-caption">
                    <div class="text-justify">{{itemCart.sku.productname}}</div>
                  </figcaption>
                </figure>
              </td>
              <td v-if="is_customize && itemCart.customized==0">...</td>
              <td v-if="itemCart.customized==1">
                <b-form-textarea
                  id="textarea"
                  v-model="items_cart[idx].customizedesc"
                  :placeholder="itemCart.customizedesc"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </td>
              <td>
                <!--<span @click="minusCart(idx)" class="btn btn-outline-dark btn-sm">-</span>-->
                <span @click="minusCart(idx)">-&nbsp;</span>
                <span class='mx-2'>{{itemCart.count}}</span>
                <!--<span @click="plusCart(idx)" class="btn btn-outline-dark btn-sm">+</span>-->
                <span @click="plusCart(idx)">&nbsp;+</span>
                <div class="text-justify">
                      <span style="color:#888888;" v-if="itemCart.sku.fans==1">{{symble_money}}{{ (lan=='jp')?parseFloat(itemCart.sku.fansprice*itemCart.currency ).toFixed(0):parseFloat(itemCart.sku.fansprice*itemCart.currency ).toFixed(2) }}</span>
                      <span style="color:#888888;" v-else>{{symble_money}}{{ (lan=='jp')?parseFloat(itemCart.sku.price*itemCart.currency ).toFixed(0):parseFloat(itemCart.sku.price*itemCart.currency ).toFixed(2) }}</span>
                      
                </div>
              </td>
              <td v-if="itemCart.sku.fans==1">{{symble_money}}{{ (lan=='jp')?parseFloat(itemCart.count*itemCart.sku.fansprice*itemCart.currency).toFixed(0):parseFloat(itemCart.count*itemCart.sku.fansprice*itemCart.currency).toFixed(2)}}</td>
              <td v-else>{{symble_money}}{{ (lan=='jp')?parseFloat(itemCart.count*itemCart.sku.price*itemCart.currency ).toFixed(0):parseFloat(itemCart.count*itemCart.sku.price*itemCart.currency ).toFixed(2)}}</td>
            </tr>
          </tbody>
        </table>
        </div>
 
     
      
      <hr>
      <div class="table-responsive" v-if="lan=='cn'">
        <table class="table">
          <tbody>
            <tr>
              <th scope="col">{{$t("message.UserName")}}</th>
              <th scope="col">{{$t("message.Addr")}}</th>
              <th scope="col">{{$t("message.Tel")}}</th>
            </tr>
            <tr v-for="(itemAddr) in items_addr" :key="itemAddr.phone" @click="setUsrInfo(itemAddr)">
              <th scope="row">{{itemAddr.contact}}</th>
              <td>{{itemAddr.address}}</td>
              <td>{{itemAddr.phone}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-if="lan=='cn'">
        <strong>{{$t("message.Shipping_address")}}:{{usr_addr}}</strong>
      </p>
      <hr>

      <b-card :title="$t('message.Total')" :header="$t('message.SHOPPING_CART_TOTALS')">
        <b-card-text>
          <p class="card-text" v-if="rate==1">{{symble_money}}{{ total_money}}</p>
          <del>
            <p class="card-text" v-if="rate<1">{{symble_money}}{{ total_money}}</p>
          </del>
          <p
            class="card-text"
            v-if="rate<1"
          >{{$t('message.Discount_price')}}:{{symble_money}}{{ parseFloat(total_money*rate ).toFixed(2)}}</p>
        </b-card-text>
        <b-form-input
          v-model="couponCode"
          :placeholder="$t('message.Please_enter_the_coupon_code')"
          v-if="!is_mem"
        ></b-form-input>
        <b-button
          v-if="ismob"
          variant="success"
          block
          size="lg"
          class="my-3"
          @click="orderConfirm()"
        ><i class="fas fa-credit-card"></i>
        {{$t("message.Submit_Order")}}</b-button>
        <b-button
          v-else
          variant="success"
          size="lg"
          class="my-3"
          @click="orderConfirm()"
        ><i class="fas fa-credit-card"></i>
        {{$t("message.Submit_Order")}}</b-button>
      </b-card>
    </div>
    <b-modal id="modal-custom" ref="modal-custom" :title="$t('message.couponTitle')" hide-footer>
      <b-form>
         <b-form-group :label="$t('message.couponEmail')" label-for="input-usr_email">
          <b-form-input id="input-usr_email" v-model="usr_email" required placeholder="Example: example@domain.com"></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('message.couponPhone')" label-for="input-usr_phone">
          <b-form-input id="input-usr_phone" v-model="usr_phone" required placeholder="Example: +8612345678"></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('message.couponCountry')" label-for="input-usr_country">
          <!-- <b-form-input id="input-usr_country" v-model="usr_country" required placeholder="Enter Country"></b-form-input> -->
          <b-form-select v-model="usr_country" id="input-usr_country">
            <option v-for="c in countrys" :value="c" :key="c.name">{{c.name}}</option>
          </b-form-select>
        </b-form-group>

        <b-form-group :label="$t('message.couponContact')" label-for="input-usr_contact">
          <b-form-input id="input-usr_contact" v-model="usr_contact" required placeholder="Example: James Jones"></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('message.couponAddress')" label-for="input-usr_addr">
          <b-form-input id="input-usr_addr" v-model="usr_addr" required placeholder="Example: XXXX W Glenrosa Ave,Phoenix,AZ"></b-form-input>
        </b-form-group>
        
        <b-form-group :label="$t('message.couponZip')" label-for="input-usr_zip">
          <b-form-input id="input-usr_zip" v-model="usr_zip" required placeholder="Example: 95140"></b-form-input>
        </b-form-group>
        <b-form-text>After completion, the express information will notify you by mail.</b-form-text>
        <b-button
          type="button"
          variant="outline-dark"
          @click="couponFreeOrder(usr_orderno, usr_email, usr_contact, usr_phone, usr_addr,usr_country,usr_zip)"
        >Submit</b-button>
      </b-form>
    </b-modal>
    <!--<Foot-section></Foot-section>-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HeadSection from "./StoreHeadSection.vue";


export default {
  name: "OrderLsPre",
  components: { HeadSection },

  data() {
    return {
      mainProps: { blank: true, width: 75, height: 75, class: 'm1' },
      items_addr: [],
      countrys:[{name:"United States"},{name:"United Kingdom"},{name:"Japan"},{name:"Russia"},{name:"Korea"},{name:"Italy"},{name:"Netherlands"},{name:"Espain"},{name:"Germany"},{name:"France"},{name:"Portugal"},{name:"Australia"},{name:"New Zealand"},{name:"Singapore"},{name:"Brazil"}],
      usr_orderno: "",
      usr_email: "",
      usr_country: "",
      usr_zip: "",
      usr_addr: "",
      usr_phone: "",
      couponCode: "",
      usr_contact: "",
      is_mem: false,
      rate: 1,
      is_customize: false
    };
  },

  computed: {
    ...mapGetters([
      "items_cart",
      "total_money",
      "lan",
      "access_token",
      "symble_money",
      "ismob"
    ])
  },
  created() {
    if (this.lan == "cn") {
      this.getCnAddrLs();
    }
    
    this.isMem();
    let referer = sessionStorage.getItem("site_referer");
        let trackid = '';
         if (referer!=null){
           trackid = JSON.parse(referer).refererid;
          // let tdate = new Date(JSON.parse(referer).date);
          // if(new Date().getTime() < tdate.getTime()){
          //     trackid = JSON.parse(referer).refererid;
          // }else{
          //   sessionStorage.removeItem("site_referer");
          // }
         }
     this.getShopCarLs(trackid);
  },
  watch: {
    lan(newlan, oldlan) {
        let referer = sessionStorage.getItem("site_referer");
        let trackid = '';
         if (referer!=null){
           trackid = JSON.parse(referer).refererid;
          // let tdate = new Date(JSON.parse(referer).date);
          // if(new Date().getTime() < tdate.getTime()){
          //     trackid = JSON.parse(referer).refererid;
          // }else{
          //   sessionStorage.removeItem("site_referer");
          // }
         }
      this.getShopCarLs(trackid);
      this.isMem();
      if (newlan == "cn") this.getCnAddrLs();
    }
  },

  methods: {
    ...mapActions({
      getShopCarLs(dispatch,trackid) {
        dispatch("getShopCarLs", {trackid}).then(data => {
          if (data.result) {
            this.$store.state.items_cart = data.items;
            console.log('-----------------items_cart---------------');
            console.log(this.$store.state.items_cart);
            for(let item in data.items){
              if(item.customized==1){
                this.is_customize = true;
              }
            }
          }
        });
      },
      delShopCartId(dispatch, bussid) {
        dispatch("delShopCartId", { bussid }).then(data => {
          if (data.result) {
            let referer = sessionStorage.getItem("site_referer");
        let trackid = '';
         if (referer!=null){
           trackid = JSON.parse(referer).refererid;
          // let tdate = new Date(JSON.parse(referer).date);
          // if(new Date().getTime() < tdate.getTime()){
          //     trackid = JSON.parse(referer).refererid;
          // }else{
          //   sessionStorage.removeItem("site_referer");
          // }
         }
            this.getShopCarLs(trackid);
          }
        });
      },
      isMem(dispatch) {
        dispatch("isMem", {}).then(data => {
          this.is_mem = data.result;
          if (data.result) {
            this.promotionRate(data.promotioncode);
          }
        });
      },
      promotionRate(dispatch, promotioncode) {
        dispatch("promotionRate", { promotioncode }).then(data => {
          this.rate = data.item.rate;
        });
      },

      getCnAddrLs(dispatch) {
        if (this.access_token == "" && this.lan== "cn") {
          this.$bvToast.toast(this.$t("message.Please_Log_In"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false
          });
          return;
        }

        dispatch("getCnAddrLs", {}).then(data => {
          if (data.result) {
            let itms = data.items;
            this.items_addr = itms;
            if (itms.length > 0) {
              this.usr_addr = itms[0].address;
              this.usr_contact = itms[0].contact;
              this.usr_phone = itms[0].phone;
            }
          } else {
            if (data.status == "logout") {
              this.showToast(this.$t("message.You_logout"));
              this.logout();
            }
          }
        });
      },

      couponFreeOrder(dispatch, orderno, email, contact, phone, address,country,zip) {
        if (orderno === "") {
          return;
        }else if (email === "") {
          this.showToast("Email cannot be empty!");
          return;
        }else if (contact === "") {
          this.showToast("Contact cannot be empty!");
          return;
        }else if ( phone === "") {
          this.showToast("Phone cannot be empty!");
          return;
        }else if (address === "") {
          this.showToast("Address cannot be empty!");
          return;
        }else if (country === "" ) {
          this.showToast("Country cannot be empty!");
          return;
        }else if (zip === "") {
          this.showToast("ZIP cannot be empty!");
          return;
        }
        address = address + "," + country.name + ",ZIP:"+zip;
        dispatch("couponFreeOrder", { orderno, email, contact, phone, address }).then(data => {
          if (data.result) {
            this.showToast("Upon completion, the express information will notify you by mail.");
            this.$refs["modal-custom"].hide();
            this.toUcenter();
          }else{
            this.showToast(data.message);
          }
        });
      },

      toUcenter() {
      if (this.access_token == "" && this.lan== "cn") {
        this.showToast(this.$t("message.Please_Log_In"));
        return;
      }
      this.$router.push({
        name: "enUcenter"
      });
    },
      orderConfirm(dispatch) {
        //   let orders = this.items_cart;

        // orders: [{"id":258,"count":4,"desc":""}]
        let len = this.items_cart.length;
        if (this.access_token == "" && this.lan== "cn") {
          this.showToast(this.$t("message.Please_Log_In"));
          return;
        }
        if (len < 1) {
          this.showToast(this.$t("message.The_Cart_Empty"));
          return;
        }

        let order = [];

        for (var i = 0; i < len; i++) {
          let item = this.items_cart[i];
          order.push({
            id: item.sku.id,
            count: item.count,
            desc: item.customizedesc
          });
        }

        // dataObj.contact = deliverObj.contact;
        // dataObj.address = deliverObj.address;
        // dataObj.phone = deliverObj.phone;

        let orders = JSON.stringify(order);
        let coupoun_code = this.couponCode;
        let referer = sessionStorage.getItem("site_referer");
        let trackid = '';
         if (referer!=null){
           trackid = JSON.parse(referer).refererid;
          // let tdate = new Date(JSON.parse(referer).date);
          // if(new Date().getTime() < tdate.getTime()){
          //     trackid = JSON.parse(referer).refererid;
          // }else{
          //   sessionStorage.removeItem("site_referer");
          // }
        }
        if (this.lan != "cn") {
          dispatch("orderConfirm", { orders, coupoun_code, trackid}).then(data => {
            if (data.result) {
              this.usr_orderno = data.orderno;
              if(data.free){
                this.$refs["modal-custom"].show();
              }else{
              var pay_signature = "";
              var pay_type = 1;
              if (this.access_token == ""){
                pay_type = 0;
              }else{
                pay_signature = this.getFromBase64(this.access_token);
              }
              window.location =
                "/paypal/checkout/vue?orderno=" +
                data.orderno +
                "&pay_signature=" +
                pay_signature +
                "&pay_type=" +
                pay_type +
                 "&lan=" +
                this.lan;
              } 
            }
          });
        } else {
          let contact = this.usr_contact;
          let address = this.usr_addr;
          let phone = this.usr_phone;
          if (contact == "") {
            this.showToast("联系人不能为空");
            return;
          }
          if (address == "") {
            this.showToast("地址不能为空");
            return;
          }
          if (phone == "") {
            this.showToast("电话号码不能为空");
            return;
          }

          dispatch("orderConfirmCn", {
            contact,
            address,
            phone,
            orders,
            coupoun_code
          }).then(data => {
            if (data.result) {
              //   var access_token = JSON.parse(this.access_token);
              var pay_signature = this.getFromBase64(this.access_token);

              window.location =
                "/alipay/payment?orderno=" +
                data.orderno +
                "&pay_signature=" +
                pay_signature;

              // window.location =
              //     "/alipay/payment?orderno=" +
              //     data.orderno +
              //     "&pay_signature=" +
              //     pay_signature;
            }
          });
        }
      },

      getFromBase64(dispatch, str) {
        var Base64 = window.Base64 || {
          /* private property*/
          _keyStr:
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

          /* public method for encoding */
          encode: function(input) {
            var output = "";
            var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            var i = 0;

            input = Base64._utf8_encode(input);

            while (i < input.length) {
              chr1 = input.charCodeAt(i++);
              chr2 = input.charCodeAt(i++);
              chr3 = input.charCodeAt(i++);

              enc1 = chr1 >> 2;
              enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
              enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
              enc4 = chr3 & 63;

              if (isNaN(chr2)) {
                enc3 = enc4 = 64;
              } else if (isNaN(chr3)) {
                enc4 = 64;
              }

              output =
                output +
                Base64._keyStr.charAt(enc1) +
                Base64._keyStr.charAt(enc2) +
                Base64._keyStr.charAt(enc3) +
                Base64._keyStr.charAt(enc4);
            }

            return output;
          },

          /* public method for decoding */
          decode: function(input) {
            var output = "";
            var chr1, chr2, chr3;
            var enc1, enc2, enc3, enc4;
            var i = 0;

            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

            while (i < input.length) {
              enc1 = Base64._keyStr.indexOf(input.charAt(i++));
              enc2 = Base64._keyStr.indexOf(input.charAt(i++));
              enc3 = Base64._keyStr.indexOf(input.charAt(i++));
              enc4 = Base64._keyStr.indexOf(input.charAt(i++));

              chr1 = (enc1 << 2) | (enc2 >> 4);
              chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
              chr3 = ((enc3 & 3) << 6) | enc4;

              output = output + String.fromCharCode(chr1);

              if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
              }
              if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
              }
            }

            output = Base64._utf8_decode(output);

            return output;
          },

          /* private method for UTF-8 encoding */
          _utf8_encode: function(string) {
            string = string.replace(/\r\n/g, "\n");
            var utftext = "";

            for (var n = 0; n < string.length; n++) {
              var c = string.charCodeAt(n);

              if (c < 128) {
                utftext += String.fromCharCode(c);
              } else if (c > 127 && c < 2048) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
              } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
              }
            }

            return utftext;
          },

          /* private method for UTF-8 decoding */
          _utf8_decode: function(utftext) {
            var string = "";
            var i = 0;
            var c3 = 0;
            var c2 = 0;
            var c = 0;

            while (i < utftext.length) {
              c = utftext.charCodeAt(i);

              if (c < 128) {
                string += String.fromCharCode(c);
                i++;
              } else if (c > 191 && c < 224) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
              } else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(
                  ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
                );
                i += 3;
              }
            }
            return string;
          }
        };
        window.Base64 = Base64;
        return Base64.encode(str);
      }
    }),
    showToast(msg) {
      this.$bvToast.toast(msg, {
        title: ``,
        toaster: "b-toaster-bottom-center",
        solid: true,
        variant: "danger",
        appendToast: false
      });
    },
    toProDet(id) {
      this.$router.replace({
        name: "enProduct",
        params: { proid: id }
      });
    },
    plusCart(i) {
      var count = this.$store.state.items_cart[i].count;
      var stock = this.$store.state.items_cart[i].sku.stock;
      if (count < stock) this.$store.state.items_cart[i].count = count + 1;
    },
    minusCart(i) {
      var count = this.$store.state.items_cart[i].count;
      if (count > 1) this.$store.state.items_cart[i].count = count - 1;
    },
    logout() {
      localStorage.removeItem("token");
        localStorage.removeItem("store");
        localStorage.removeItem("usrname");
        localStorage.removeItem("usrid");
        this.$store.state.usr = "";
        this.$store.state.usrid = "";
        this.$store.state.access_token = "";
        this.$store.state.items_cart = [];
    },
    setUsrInfo(items_addr) {
      this.usr_addr = items_addr.address;
      this.usr_contact = items_addr.contact;
      this.usr_phone = items_addr.phone;
    }
  }
};
</script>
<style>
</style>
